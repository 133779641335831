<template>
  <div class="p-20 bg-white">
    <a-space class="w-full" :size="20" direction="vertical">
      <Leader
        :AuditStatus="AuditStatus"
        :dataSource="origin"
        :status.sync="status.leader"
        :save="saveInitiator"
      />
      <div class="text-right">
        <a-button
          v-if="isManager && AuditStatus === 1"
          size="large"
          type="primary"
          @click="modal = true"
        >
          审核
        </a-button>
        <a-button
          v-else-if="!isManager && AuditStatus === 0"
          size="large"
          type="primary"
          @click="submitRecoginze"
          >提交</a-button
        >
      </div>
    </a-space>
    <a-modal
      v-model="modal"
      title="审核"
      okText="审核"
      cancelText="取消"
      @ok="evaluateRecoginze"
    >
      <a-form-model :model="evaluate" ref="container">
        <a-form-model-item>
          <a-select v-model="evaluate.AuditStatus">
            <a-select-option :value="2">审核通过</a-select-option>
            <a-select-option :value="3">审核失败</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="evaluate.AuditStatus === 3">
          <gf-textarea
            :required="true"
            prop="Feedback"
            :max="300"
            :rows="5"
            v-model="evaluate.Feedback"
          ></gf-textarea>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import Leader from "./Leader.vue";
import { saveCompanyRecoginze, getCompanyRecoginze } from "@/api";
import { updateProCompany } from "@/api/company.js";
import {
  submitInitiator,
  getProCompany,
  evaluateProInitiator,
} from "@/api/company.js";
export default {
  components: {
    Leader,
  },
  data() {
    return {
      origin: {},
      status: {},
      evaluate: {
        AuditStatus: 2,
        Feedback: "",
      },
      AuditStatus: 0,
      modal: false,
    };
  },
  computed: {
    role() {
      return this.$store.state.role;
    },
    isManager() {
      return this.role === 6 || this.role === 7;
    },
  },
  created() {
    const id = this.$route.params.id;
    this.id = id;
    if (id && this.isManager) {
      this.getProInitiator(id);
    } else {
      this.getInitiator();
    }
  },
  methods: {
    getProInitiator(id) {
      getProCompany(id).then(({ data }) => {
        this.origin = data.Detail ? data.Detail.Initiator || {} : {};
        this.ID = data.ID;
        this.AuditStatus = this.origin.AuditStatus;
      });
    },
    getInitiator() {
      if (this.isManager) {
        this.getProInitiator(this.ID);
      } else {
        getCompanyRecoginze().then(({ data }) => {
          this.origin = data.Detail ? data.Detail.Initiator || {} : {};
          this.AuditStatus = this.origin.AuditStatus;
        });
      }
    },
    saveInitiator(data) {
      if (this.isManager) {
        return updateProCompany(this.ID, { Initiator: data }).then(() => {
          this.getInitiator();
          return Promise.resolve();
        });
      } else {
        return saveCompanyRecoginze({ Initiator: data }).then(() => {
          this.getInitiator();
          return Promise.resolve();
        });
      }
    },
    submitRecoginze() {
      let status = true;
      for (let key in this.status) {
        if (!this.status[key]) {
          status = false;
        }
      }
      if (status) {
        submitInitiator().then(() => {
          this.$message.success("提交成功");
          this.getInitiator();
        });
      } else {
        this.$message.error("必填资料填写不完整");
      }
    },
    evaluateRecoginze() {
      evaluateProInitiator(this.id, this.evaluate).then(() => {
        this.$message.success("提交成功");
        this.modal = false;
        this.getProInitiator(this.id);
      });
    },
    validate() {},
  },
};
</script>
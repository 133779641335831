<template>
  <div>
    <edit-block
      :showStatus="true"
      :AuditStatus="dataSource.AuditStatus"
      :state="state"
      :title="title"
    >
      <!-- 未填写按钮 -->
      <div slot="opt">
        <span @click="updateState(1)" class="text-primary cursor-pointer"
          >添加</span
        >
      </div>
      <!-- 填写中 -->
      <LeaderEdit
        @cancel="cancel"
        @save="finish"
        :model="model"
        slot="editing"
        :options="options"
      />
      <!-- 已填写 -->
      <LeaderDisplay :options="options" :model="model" slot="display" />
      <!-- 已填写按钮 -->
      <div slot="display-opt">
        <div class="display-opt" slot="display-opt">
          <a-space @click="updateState(1)" class="cursor-pointer text-primary">
            <img src="@/assets/icons/modify.svg" alt="" />
            <span>修改</span>
          </a-space>
        </div>
      </div>
    </edit-block>
  </div>
</template>

<script>
import LeaderEdit from "@/components/user/leader/editing.vue";
import LeaderDisplay from "@/components/user/leader/display.vue";

export default {
  components: { LeaderDisplay, LeaderEdit },
  props: {
    dataSource: {
      type: Object,
      default: () => ({}),
    },
    AuditStatus: Number,
    save: Function,
  },
  inject: ["options"],
  data() {
    return {
      title: "会员负责人",
      model: {},
      state: 0,
    };
  },
  methods: {
    transform(obj) {
      const temp = { ...obj };

      temp.Gender = temp.Gender;
      temp.Political = temp.Political || null;
      temp.Photo = temp.Photo || {};
      temp.BirthdayTime = temp.BirthdayTime
        ? temp.BirthdayTime + ""
        : undefined;

      this.updateState(this.getState(temp));
      this.validate(temp);

      return temp;
    },
    restore(obj) {
      const temp = { ...obj };
      temp.BirthdayTime = +temp.BirthdayTime;
      if (temp.Political) {
        temp.Political = {
          ID: temp.Political,
          Name: this.options.political[temp.Political - 1].Name,
        };
      }

      return temp;
    },
    getState({
      Name,
      Gender,
      BirthdayTime,
      Position,
      Phone,
      Email,
      Desc,
      Photo,
      Identity,
      Work,
      Political,
      Wechat,
    }) {
      if (
        Name ||
        Gender ||
        BirthdayTime ||
        Position ||
        Phone ||
        Email ||
        Desc ||
        Photo.ID ||
        Identity ||
        Work ||
        Political ||
        Wechat
      ) {
        return 2;
      }

      return 0;
    },
    validate({ Name, Gender, BirthdayTime, Position, Phone, Email, Desc }) {
      let status = true;
      if (
        !Name ||
        (!Gender && Gender !== 0) ||
        !BirthdayTime ||
        !Position ||
        !Phone ||
        !Email ||
        !Desc
      ) {
        status = false;
      }
      this.$emit("update:status", status);
    },
    finish(data) {
      const temp = this.restore(data);
      this.save(temp).then(() => {
        this.$message.success("保存成功");
        this.model = this.transform(temp);
      });
    },
    cancel() {
      this.updateState(this.getState(this.model));
    },
    updateState(state) {
      switch (state) {
        case 0:
          this.title = "会员负责人";
          break;
        case 1:
          if (this.state === 0) {
            this.title = "添加会员负责人";
          } else if (this.state === 2) {
            this.title = "修改会员负责人";
          }
          break;
        case 2:
          this.title = "会员负责人";
          break;
      }
      this.state = state;
    },
  },
  watch: {
    dataSource: {
      handler(val) {
        this.model = this.transform(val);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>